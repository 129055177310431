class Loading {
  constructor() {
    this.cookie = document.querySelector('.cookie__content');
    this.close = document.querySelector('.cookie__close')
    this.agree = document.querySelector('.js-cookie-agree')
    this.cacheName = 'loadingCache';

    this.isTopPage = window.location.pathname === '/' || window.location.pathname === '/en/';

    this.setting();
    this.closeClick();
    this.agreeClick();
  }

  setting() {
    if (this.isTopPage) {
      const isAgree = localStorage.getItem(this.cacheName);
      if (!isAgree) {
        this.cookie.classList.add('display');
      }
    }
  }

  closeClick() {
    this.close.addEventListener('click', () => {
      this.cookie.classList.add('none');
    }) 
  }

  agreeClick() {
    this.agree.addEventListener('click', () => {
      this.cookie.classList.add('none');
      let agree = localStorage.setItem(this.cacheName, 1);
    })
  }
}

new Loading();
