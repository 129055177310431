let titles = [...document.querySelectorAll('.js-inview')];

let observerEnter = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is-inview');

      observer.unobserve(entry.target);
    }
  });
};

let observer = new IntersectionObserver(observerEnter);

titles.forEach((title) => {
  observer.observe(title);
});
