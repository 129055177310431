const logo = document.querySelector('.js_home-logo');
const logosp = document.querySelector('.js-logoview');
const logoHeader = document.querySelector('.js_home-logo');

const navigationLinks = [
  {id: 'home', url: '/'},
  {id: 'cafe', url: '/cafe/index.html'},
  {id: 'about', url: '/cafe/about/index.html'}
];

const currentPath = window.location.pathname;

logo.style.visibility = 'visible';

for (const link of navigationLinks) {
  const linkElement = document.querySelector(`#${link.id}`);
  if (currentPath === link.url) {
    // logo.style.visibility = 'hidden';
    linkElement.style.color = '#003872';
  }
  if (currentPath === '/') {
    logo.style.visibility = 'hidden';
  }
}

const pCont = document.querySelector('.p-topInfo__overlay');


ScrollTrigger.create({
  trigger: '.p-topInfo__overlay',
  start: "top top",
  end: "bottom top",
  onEnter: () => {
    gsap.to(logosp, {"position": "fixed"});
  },
  onLeaveBack: () => {
    gsap.to(logosp, {"position": "absolute"});
  },
  // markers: true,
})

ScrollTrigger.create({
  trigger: '.js-footer',
  start: "-100px top",
  end: "bottom top",
  onEnter: () => {
    gsap.to(logosp, {"visibility": "hidden"});
  },
  onLeaveBack: () => {
    gsap.to(logosp, {"visibility": "visible"});
  },
  // markers: true,
})

ScrollTrigger.create({
  trigger: '.js-footer',
  start: "-70px top",
  end: "bottom top",
  onEnter: () => {
    if (currentPath === '/') {
      gsap.to(logosp, {"visibility": "hidden"});
    } else {
      gsap.to(logoHeader, {"visibility": "hidden"});
    }
  },
  onLeaveBack: () => {
    if (currentPath === '/') {
      gsap.to(logosp, {"visibility": "visible"});
    } else {
      gsap.to(logoHeader, {"visibility": "visible"});
    }
  },
  // markers: true,
})
