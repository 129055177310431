const swiper = new Swiper(".swiper", {
  slidesPerView: 1.3,
  spaceBetween: 20,

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    520: {
      slidesPerView: 2.4,
    },
    959: {
      slidesPerView: 3.4,
    }
  }
});

const footerSwiper = new Swiper(".footerSwiper", {
  slidesPerView: 2,
  spaceBetween: 15,
  loop: true,

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    959: {
      slidesPerView: 4,
      spaceBetween: 20,
    }
  }
});
