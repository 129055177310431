class MenuToggle {
  constructor(menuSelector, menuOpenSelector, menuLineSelector) {
    this.menu = document.querySelector(menuSelector);
    this.menuOpen = document.querySelector(menuOpenSelector);
    this.menuLine = document.querySelector(menuLineSelector);
    this.body = document.body;

    this.menu.addEventListener('click', () => this.toggleMenu());
  }

  toggleMenu() {
    const isOpen = this.menuOpen.classList.contains('js-menu-open');
    this.menuOpen.classList.toggle('js-menu-open', !isOpen);
    this.menuLine.classList.toggle('js-menu-open', !isOpen);
    this.body.style.overflow = isOpen ? 'auto' : 'hidden';
  }
}

const menuToggle = new MenuToggle('.js-menu', '.l-menu', '.js-menu-line');
