// globals
const swiper = new Swiper(".swiper", {
  slidesPerView: 1.3,
  spaceBetween: 20,

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    520: {
      slidesPerView: 2.4,
    },
    959: {
      slidesPerView: 3.4,
    }
  }
});

const footerSwiper = new Swiper(".footerSwiper", {
  slidesPerView: 2,
  spaceBetween: 15,
  loop: true,

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    959: {
      slidesPerView: 4,
      spaceBetween: 20,
    }
  }
});

class MenuToggle {
  constructor(menuSelector, menuOpenSelector, menuLineSelector) {
    this.menu = document.querySelector(menuSelector);
    this.menuOpen = document.querySelector(menuOpenSelector);
    this.menuLine = document.querySelector(menuLineSelector);
    this.body = document.body;

    this.menu.addEventListener('click', () => this.toggleMenu());
  }

  toggleMenu() {
    const isOpen = this.menuOpen.classList.contains('js-menu-open');
    this.menuOpen.classList.toggle('js-menu-open', !isOpen);
    this.menuLine.classList.toggle('js-menu-open', !isOpen);
    this.body.style.overflow = isOpen ? 'auto' : 'hidden';
  }
}

const menuToggle = new MenuToggle('.js-menu', '.l-menu', '.js-menu-line');

const logo = document.querySelector('.js_home-logo');
const logosp = document.querySelector('.js-logoview');
const logoHeader = document.querySelector('.js_home-logo');

const navigationLinks = [
  {id: 'home', url: '/'},
  {id: 'cafe', url: '/cafe/index.html'},
  {id: 'about', url: '/cafe/about/index.html'}
];

const currentPath = window.location.pathname;

logo.style.visibility = 'visible';

for (const link of navigationLinks) {
  const linkElement = document.querySelector(`#${link.id}`);
  if (currentPath === link.url) {
    // logo.style.visibility = 'hidden';
    linkElement.style.color = '#003872';
  }
  if (currentPath === '/') {
    logo.style.visibility = 'hidden';
  }
}

const pCont = document.querySelector('.p-topInfo__overlay');


ScrollTrigger.create({
  trigger: '.p-topInfo__overlay',
  start: "top top",
  end: "bottom top",
  onEnter: () => {
    gsap.to(logosp, {"position": "fixed"});
  },
  onLeaveBack: () => {
    gsap.to(logosp, {"position": "absolute"});
  },
  // markers: true,
})

ScrollTrigger.create({
  trigger: '.js-footer',
  start: "-100px top",
  end: "bottom top",
  onEnter: () => {
    gsap.to(logosp, {"visibility": "hidden"});
  },
  onLeaveBack: () => {
    gsap.to(logosp, {"visibility": "visible"});
  },
  // markers: true,
})

ScrollTrigger.create({
  trigger: '.js-footer',
  start: "-70px top",
  end: "bottom top",
  onEnter: () => {
    if (currentPath === '/') {
      gsap.to(logosp, {"visibility": "hidden"});
    } else {
      gsap.to(logoHeader, {"visibility": "hidden"});
    }
  },
  onLeaveBack: () => {
    if (currentPath === '/') {
      gsap.to(logosp, {"visibility": "visible"});
    } else {
      gsap.to(logoHeader, {"visibility": "visible"});
    }
  },
  // markers: true,
})

let titles = [...document.querySelectorAll('.js-inview')];

let observerEnter = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is-inview');

      observer.unobserve(entry.target);
    }
  });
};

let observer = new IntersectionObserver(observerEnter);

titles.forEach((title) => {
  observer.observe(title);
});

class Loading {
  constructor() {
    this.cookie = document.querySelector('.cookie__content');
    this.close = document.querySelector('.cookie__close')
    this.agree = document.querySelector('.js-cookie-agree')
    this.cacheName = 'loadingCache';

    this.isTopPage = window.location.pathname === '/' || window.location.pathname === '/en/';

    this.setting();
    this.closeClick();
    this.agreeClick();
  }

  setting() {
    if (this.isTopPage) {
      const isAgree = localStorage.getItem(this.cacheName);
      if (!isAgree) {
        this.cookie.classList.add('display');
      }
    }
  }

  closeClick() {
    this.close.addEventListener('click', () => {
      this.cookie.classList.add('none');
    }) 
  }

  agreeClick() {
    this.agree.addEventListener('click', () => {
      this.cookie.classList.add('none');
      let agree = localStorage.setItem(this.cacheName, 1);
    })
  }
}

new Loading();

const langSelect = document.querySelector('.l-header__language select');
if( langSelect ) {
  langSelect.addEventListener('change', ()=>{
    location.href = langSelect.value;
  });
}

(() => {

})();

//DOMContentLoaded
$(() => {
});

//images resources loaded
$(window).on('load', () => {

  $(window).trigger('loading');
});

//after loading animation
$(window).on('loading', () => {

});

// スマホ・タブレットの向き判定
// $(window).on('orientationchange', ()=>{
//   if(isPortrait()){
//     console.log('isPortrait');
//   } else {
//     console.log('isLandscape');
//   }
// });
